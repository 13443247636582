import React from 'react'

const Banner = ({ title, titleLine2, className }) => {
  return (
    <header className={`header lg:px-20 py-0 leading-none hidden md:block relative my-20 ${className}`}>
      <h1 className={`${titleLine2 ? 'pb-0 leading-14' : 'mb-4'}`}>{title}</h1>
      <h1 className={`${titleLine2 ? 'mb-4 leading-14' : 'hidden'}`}>{titleLine2}</h1>
    </header>
  )
}

export default Banner
